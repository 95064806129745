import { useTranslation } from "next-i18next";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import Trans from "~/types/translationKeys";
import Button from "../Button";
import { Container } from "../Container";
import Text from "../Text";
import styles from "./CookieBanner.module.scss";

const CookieBanner: FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(!localStorage.getItem("cookie"));
  }, []);

  const closeBanner = () => {
    setOpen(false);
    localStorage.setItem("cookie", "true");
  };

  if (!open) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Container className="flex flex-col gap-5 py-6 md:flex-row">
        <div className="flex flex-col flex-1 gap-2">
          <Text type="title-2" color="white">
            {t(Trans.we_respect_your_privacy)}
          </Text>
          <Text color="white">
            {t(Trans.cookie_message)}{" "}
            <Link
              className={styles.link}
              href="/privacy-policy"
              target="_blank"
            >
              {t(Trans.cookie_policy)}
            </Link>
          </Text>
        </div>
        <div className="flex gap-3">
          <Button type="outline" variant="white" onClick={closeBanner}>
            {t(Trans.reject_all)}
          </Button>
          <Button onClick={closeBanner}>{t(Trans.accept_all)}</Button>
        </div>
      </Container>
    </div>
  );
};

export default CookieBanner;
